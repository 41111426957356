import { all } from "redux-saga/effects";
import Websocket from "./Websocket";

export default function* rootSaga() {
  // Yields:
  // 1. Websocket, if live update is needed
  // 2. Empty, if live update is not needed
  // To disable live update, comment out the line below and uncomment the one after it
  yield all([Websocket()]);
  // yield all();
}
